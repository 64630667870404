import React from "react";
import * as S from "./Thankyou.module.sass";
import "../sass/website.global.sass";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const IndexPage = () => {
  return (
    <Layout title={`Thank You`}>
      <div className={S.Thankyou}>
        <div className={S.WaterMark} />
        <main className={S.Main}>
          <div className={S.Inner}>
            <div className={S.Logo}>
              <StaticImage
                src={"../images/lakeview-logo-white.svg"}
                alt={"logo"}
                placeholder={`none`}
                imgStyle={{ maxHeight: `60px` }}
                style={{ maxHeight: `60px` }}
                objectFit={`contain`}
              />
            </div>
            <div className={S.Text}>
              <h1>Thank you for registering.</h1>
              <p>
                You have been added to our Priority Access List and will receive
                exclusive updates on the Residences at Lakeview Village in
                addition to having one of the first opportunities to purchase
                this fall. Our sales team will reach out in the coming weeks to
                provide additional information.
              </p>
            </div>
            <div className={S.Disclaimer}>
              The developer reserves the right to make any modifications or
              substitutions, without notice, should they be necessary. Any
              measurements provided are approximate only and actual final sizing
              may vary. Renderings are an artist’s conception only and actual
              final product may vary and is subject to change. This is not an
              offering for sale. Any such offering must be made by way of
              disclosure statement. E.&O.E. Sales and Marketing provided by Epic
              Real Estate Solutions
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default IndexPage;
